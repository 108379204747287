<template>
  <div>
    <div v-if="options.length > 0">
      <div class="row">
        <h3>Current USP</h3>
      </div>
      <div class="row" v-for="(usp, index) in options" style="margin-bottom: 10px">
        <div style="display: inline-flex; gap: 12px;">
          <v-input v-model="usp.name" placeHolderText="usp" :disabled="usp.disabled"/>
          <baseArrayCRUDCreateButtons :option="usp"
                                      :index="index"
                                      :array="options"
                                      @edit="editOption"
                                      @reset="resetOption"
                                      @remove="removeOption"/>
        </div>
      </div>
    </div>
    <div v-if="newOptions.length > 0">
      <div class="row">
        <h3>Nieuw</h3>
      </div>
      <div class="row" v-for="(usp, index) in newOptions" style="margin-bottom: 10px">
        <div style="display: inline-flex; gap: 12px;">
          <v-input v-model="usp.name" placeHolderText="usp" :disabled="usp.disabled"/>
          <baseArrayCRUDEditButtons :index="index"
                                    :array="newOptions"
                                    @remove="removeNewOption"/>
        </div>
      </div>
    </div>
    <div v-if="removedOptions.length > 0">
      <div class="row">
        <h3>Verwijderd</h3>
      </div>
      <div class="row" v-for="(removedUsp, index) in removedOptions">
        <div style="display: inline-flex; gap: 12px">
          <v-input v-model="removedUsp.name" placeHolderText="usp" :disabled="true"/>
          <baseArrayCRUDRemovedButtons :index="index"
                                       :array="removedOptions"
                                       @reAdd="reAddOption"/>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="v-input">
        <button class="btn btn-primary pull-right v-input" @click="addOption"><i class="material-icons">add</i></button>
      </div>
    </div>
    <div class="row">
      <button class="btn btn-primary v-input" @click="saveOption"><i class="material-icons">save</i> Opslaan</button>
    </div>
  </div>
</template>

<script>
import baseArrayCRUD from "@/components/shared/baseArrayCRUD";
import baseArrayCRUDCreateButtons from "@/components/shared/baseArrayCRUDCreateButtons";
import baseArrayCRUDEditButtons from "@/components/shared/baseArrayCRUDEditButtons";
import baseArrayCRUDRemovedButtons from "@/components/shared/baseArrayCRUDRemovedButtons";

export default {
  extends: baseArrayCRUD,
  components: {
    baseArrayCRUDCreateButtons,
    baseArrayCRUDEditButtons,
    baseArrayCRUDRemovedButtons
  },
  created() {
    setTimeout(() => {
      this.addOptions([
        {
          name: {value: '1ste eigenaar'},
        },
        {
          name: {value: 'Special edition'}
        },
        {
          name: {value: 'Niet gestolen'},
        }
      ])
    }, 2500)
  },
  methods: {
    addOption() {
      this.newOptions.push({
        name: null,
        defaultValue: null,
        disabled: false
      })
    },
    saveOption() {
      console.log('Changed options')
      console.log(this.changedOptions)
      console.log('New options')
      console.log(this.newOptions)
      console.log('Deleted')
      console.log(this.removedOptions)
    },
  }
}
</script>